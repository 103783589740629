import { FluidObject } from "gatsby-image"
import { IMeta } from "./meta"
export interface IFAQ {
  title: string
  text: string
}

export enum ResourceCategory {
  inspired = "get inspired",
  ready = "get ready",
  going = "get going",
  wp = "white papers",
}

export type IResourceCategory =
  | "get inspired"
  | "get ready"
  | "get going"
  | "white papers"

export interface IResource {
  title?: string
  text?: string
  slug?: string
  teaser?: string
  category?: IResourceCategory
  link?: string
  image?: {
    alt?: string
    url?: FluidObject
    imageUrl?: string
  }
}

export interface IResourcesPage {
  meta: IMeta
  page: {
    title: string
    intro?: string
    resources?: IResource[]
  }
}
