import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// Styling
import textStyles from "../styles/textStyles"
import colors, { shadows } from "../styles/colors"
import mq from "../styles/breakpoints"

// Components
import Seo from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"
import Button from "../components/atoms/Button"
import RequestDemo from "../components/RequestDemo"

// Constants
import zap from "../images/zap.svg"

// Hooks
import { useLocale } from "../hooks/useLocale"

// Types
import {
  IResourcesPage,
  IResource,
  ResourceCategory,
} from "../prismic-types/resources"
import { IDemoPage } from "../prismic-types/request-demo-page"

// Utils
import { verifyDownloadUrl } from "../utils/hashUrl"

interface IProps {
  content: IResourcesPage
  demoPage: IDemoPage
}

interface IResourceList {
  resources: IResource[]
  allowDownload: boolean
  downloadClick: (title?: string) => void
}

export const ResourcesList: React.FC<IResourceList> = ({
  resources,
  downloadClick,
  allowDownload,
}) => {
  const { getLocalizedLink, isDutch } = useLocale()

  return (
    <ResourcesContainer>
      {resources.map((resource, index) => (
        <Card key={`resource-card-${index}`} allowDownload={allowDownload}>
          <TextWrapper>
            <TextSection>
              <CardTitle>{resource.title}</CardTitle>
              <CardDescription>{resource.text}</CardDescription>
            </TextSection>
            {allowDownload ? (
              <DownloadButtonContainer>
                <DownloadButton
                  variant="download"
                  label="Download"
                  isExternal
                  href={resource.link || ""}
                />
              </DownloadButtonContainer>
            ) : (
              <ButtonContainer>
                <Button
                  variant="download"
                  onClick={() => downloadClick(resource.title)}
                />
                <StyledButton
                  href={getLocalizedLink(`/resources/${resource.slug}`)}
                  label={isDutch ? "Lees verder" : "Read more"}
                  variant="secondary"
                />
              </ButtonContainer>
            )}
          </TextWrapper>
          <ImageWrapper src={resource.image?.url?.src} />
        </Card>
      ))}
    </ResourcesContainer>
  )
}

const Resources: React.FC<IProps> = ({ content, demoPage }) => {
  const [requestedResource, setRequestedResource] = useState<string>()
  const [allowDownload, setAllowDownload] = useState<boolean>(false)
  const slug = "resources"

  useEffect(() => {
    const isVerified = verifyDownloadUrl(window.location.href)
    setAllowDownload(isVerified)
  }, [])

  const filteredResources = Object.values(ResourceCategory).map((cat) =>
    content.page.resources?.filter((res) => res.category === cat)
  )

  return (
    <>
      <Seo
        title={content.meta.title}
        description={content.meta.description}
        dateModified={content.meta.modifiedDate}
        datePublished={content.meta.publishedDate}
        lang={content.meta.lang}
        slug={slug}
        breadCrumbItems={[{ name: "Resources", url: slug }]}
      />
      <Section>
        <IntroContainer>
          {content.page.title && (
            <Title>{allowDownload ? "Downloads" : content.page.title}</Title>
          )}
          {content.page.intro && (
            <Text dangerouslySetInnerHTML={{ __html: content.page.intro }} />
          )}
        </IntroContainer>
        {content.page.resources &&
          filteredResources &&
          filteredResources.map((resources) => {
            if (resources && resources.length > 0) {
              const categoryTitle = resources[0].category
                ? resources[0].category.charAt(0).toUpperCase() +
                  resources[0].category.slice(1)
                : undefined
              return (
                <ContentWrapper key={`resource-category-${categoryTitle}`}>
                  {categoryTitle && (
                    <CategoryTitle>{categoryTitle}</CategoryTitle>
                  )}
                  <ResourcesList
                    resources={resources}
                    allowDownload={allowDownload}
                    downloadClick={(title) => setRequestedResource(title)}
                  />
                </ContentWrapper>
              )
            }
          })}
      </Section>
      {requestedResource && (
        <RequestDemo
          title={demoPage.downloadTitle}
          text={demoPage.downloadText}
          variant="download"
          labels={demoPage.labels}
          download={requestedResource}
          closeModal={() => setRequestedResource(undefined)}
          onSuccess={() => {
            setRequestedResource(undefined)
          }}
        />
      )}
    </>
  )
}

const Section = styled.section`
  background: ${colors.grayPurpleLight};
  padding-bottom: 120px;
`

const IntroContainer = styled(ContentWrapper)`
  padding-top: 104px;
  padding-bottom: 48px;

  ${mq.from.M`
    padding-top: 168px;

    > * {
      max-width: 70%;
    }
  `}
`

const Title = styled.h1`
  color: ${colors.main["100"]};
  ${textStyles.titleL};
  margin: 0 0 4px;
`

const CategoryTitle = styled.h3`
  color: ${colors.main["100"]};
  ${textStyles.titleM};
  margin: 0 0 24px;
`

const Text = styled.div`
  color: ${colors.main["300"]};
  > p {
    ${textStyles.body}
    margin: 0 0 24px;
  }

  > p:last-child {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 12px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -26px;
        top: 4px;
        width: 18px;
        height: 18px;
        background-image: url("${zap}");
        background-position: center 50%;
        background-repeat: no-repeat;
      }
    }
  }

  ${mq.from.M`
    > p {
      font-size: 20px;
    }
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  gap: 16px;

  > *:first-child {
    flex: 0 0 auto;
  }
`

const DownloadButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DownloadButton = styled(Button)`
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ResourcesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
`

const Card = styled.div<{ allowDownload: boolean }>`
  width: 100%;
  background: ${colors.white};
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 32px;
  row-gap: 16px;
  ${shadows.depthOne};

  ${mq.from.M`
    width: calc(50% - 16px);
    flex-direction: ${({ allowDownload }: { allowDownload: boolean }) =>
      allowDownload ? "row-reverse" : "row"};
    justify-content: space-between;
  `}
`

const CardTitle = styled.h5`
  color: ${colors.main["200"]};
  ${textStyles.titleS};
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  ${mq.from.M`
    font-size: 22px;
    font-weight: 500;
  `}
  margin: 0 0 16px;
`

const CardDescription = styled.p`
  color: ${colors.main["400"]};
  ${textStyles.body};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0px;
  ${mq.from.M`
    width: calc(66.66% - 8px);
  `}
`

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageWrapper = styled.div<{ src?: string }>`
  background: ${colors.grey};
  background-image: url("${({ src }) => src}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  min-height: 128px;
  width: 100%;
  margin-bottom: 16px;

  ${mq.from.S`
    min-height: 152px;
  `}

  ${mq.from.M`
    width: calc(33.33% - 8px);
    height: 100%;
    margin-bottom: 0;
  `}
`

export const query = graphql`
  query resourcesPage($locale: String!) {
    prismicFaqPage(lang: { eq: $locale }) {
      lang
      first_publication_date
      last_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Page
        title {
          text
        }
        intro {
          html
        }
        resources {
          resource_title {
            text
          }
          resource_description {
            html
          }
          resource_link {
            url
          }
          resource_category
          resource_slug
          resource_teaser
          resource_image {
            alt
            url
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
    prismicRequestADemoPage(lang: { eq: $locale }) {
      last_publication_date
      lang
      first_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Page
        title {
          text
        }
        text {
          html
        }
        download_title {
          text
        }
        download_text {
          html
        }
        name
        notes
        phone
        email
        company
        interest
        employees
      }
    }
  }
`

export default ({ data }: any) => {
  if (!data) return null
  const {
    data: raw,
    lang,
    first_publication_date,
    last_publication_date,
  } = data.prismicFaqPage
  const { data: demoPageData } = data.prismicRequestADemoPage

  if (!raw) return null

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: first_publication_date,
    modifiedDate: last_publication_date,
    lang,
  }

  const page = {
    title: raw.title?.text,
    intro: raw.intro?.html,
    resources: raw.resources.map((resource: any) => ({
      title: resource.resource_title?.text,
      text: resource.resource_teaser,
      slug: resource.resource_slug,
      category: resource.resource_category,
      link: resource.resource_link.url,
      image: {
        alt: resource.resource_image?.alt,
        url: resource.resource_image?.fluid,
        imageUrl: resource.resource_image?.url,
      },
    })),
  }

  const demoPage: IDemoPage = {
    text: demoPageData.text.html,
    title: demoPageData.title.text,
    downloadText: demoPageData.download_text.html,
    downloadTitle: demoPageData.download_title.text,
    labels: {
      name: demoPageData.name,
      company: demoPageData.company,
      email: demoPageData.email,
      notes: demoPageData.notes,
      phone: demoPageData.phone,
      interest: demoPageData.interest,
      employees: demoPageData.employees,
    },
  }

  const content = {
    meta,
    page,
  }

  return <Resources content={content} demoPage={demoPage} />
}
