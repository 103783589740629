import React, { useState } from "react"
import GatsbyImage from "gatsby-image"
import styled from "styled-components"

// Utils
import { getUrl, paths } from "../constants/paths"

// Styles
import textStyles, { fontWeights } from "../styles/textStyles"
import colors from "../styles/colors"
import mq from "../styles/breakpoints"
import { fonts } from "../styles/fonts"

// Components
import Seo from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"
import Button from "../components/atoms/Button"
import RequestDemo from "../components/RequestDemo"
import { ResourcesList } from "../pages/resources"
import BottomSection from "../components/BottomSection"

// Types
import { IResource } from "../prismic-types/resources"
import { IDemoPage } from "../prismic-types/request-demo-page"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"
import { graphql } from "gatsby"

interface IOurStory {
  title: string
  text: string
  buttonLabel: string
  buttonLink: string
  backgroundImage: string
}
interface IProps extends IResource {
  className?: string
  resources?: IResource[]
  lang: string
  demoPage: IDemoPage
  ourStory: IOurStory
}

const ResourcePost: React.FC<IProps> = ({
  title,
  teaser,
  category,
  text,
  image,
  resources,
  lang,
  demoPage,
  slug: _slug,
  ourStory,
}) => {
  const { getLocalizedLink, isDutch } = useLocale()
  const [requestedResource, setRequestedResource] = useState<string>()
  const [allowDownload, setAllowDownload] = useState(false)
  const slug = `resources/${_slug}`

  const otherCategoryResources =
    resources &&
    resources.filter(
      (resource) => resource.title !== title && resource.category === category
    )

  return (
    <>
      <Seo
        title={title || "Resource page"}
        description={teaser}
        dateModified=""
        datePublished=""
        lang={lang}
        breadCrumbItems={[
          { name: "Resources", url: "resources" },
          { name: title || "", url: `resources/${slug}` },
        ]}
        imageUrl={image?.imageUrl}
        slug={slug}
      />
      <StyledContentWrapper>
        {category && <Subtitle>{category}</Subtitle>}
        {title && (
          <Wrapper>
            <Title>{title}</Title>
            <Button
              label="Download"
              variant="download"
              onClick={() => setRequestedResource(title)}
            />
          </Wrapper>
        )}
        {(text || image) && (
          <MainContentWrapper>
            {text && <Paragraph dangerouslySetInnerHTML={{ __html: text }} />}
            {image?.url && (
              <ImageWrapper>
                <GatsbyImage fluid={image.url} alt={image.alt || title} />
              </ImageWrapper>
            )}
          </MainContentWrapper>
        )}
        <Wrapper>
          {otherCategoryResources && otherCategoryResources.length > 0 && (
            <SmallTitle>
              {isDutch ? "Andere resources" : "Other resources"}
            </SmallTitle>
          )}
          <StyledButton
            fullWidth={false}
            position={
              !otherCategoryResources || otherCategoryResources.length === 0
                ? "center"
                : "right"
            }
            center={
              !otherCategoryResources || otherCategoryResources.length === 0
            }
            label={isDutch ? "Bekijk alle resources" : "See all resources"}
            variant="secondary"
            href={getLocalizedLink(paths.resources)}
          />
        </Wrapper>
        {otherCategoryResources && otherCategoryResources.length > 0 && (
          <ResourcesList
            allowDownload={allowDownload}
            resources={otherCategoryResources}
            downloadClick={(t) => setRequestedResource(t)}
          />
        )}
      </StyledContentWrapper>
      {ourStory.title && (
        <BottomSection
          title={ourStory.title}
          text={ourStory.text}
          buttonLabel={ourStory.buttonLabel}
          titleFontSize="48px"
          sectionId="our-story"
          titleColor={colors.white}
          textColor={colors.white}
          buttonLink={getUrl(ourStory.buttonLink)}
          background={{
            type: "imageWithGradient",
            imageUrl: ourStory.backgroundImage,
            gradientStartColor: colors.white,
            gradientEndColor: colors.purpleLightDim,
            gradientStartPercentage: 0,
            gradientEndPercentage: 0,
          }}
          buttonVariant="tertiary"
        />
      )}
      {requestedResource && (
        <RequestDemo
          title={demoPage.downloadTitle}
          text={demoPage.downloadText}
          variant="download"
          labels={demoPage.labels}
          download={title}
          closeModal={() => setRequestedResource(undefined)}
          onSuccess={() => setAllowDownload(true)}
        />
      )}
    </>
  )
}

const StyledButton = styled(Button)<{ center: boolean }>`
  ${({ center }) =>
    center &&
    `
    margin: 0 auto;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  padding-top: 100px;

  ${mq.from.M`
    padding-top: 160px;
  `}

  ${mq.from.L`
    padding-top: 200px;
    max-width: calc(10/12*100%);
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;

  ${mq.from.M`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  `}
`

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 64px;

  ${mq.from.M`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 88px;
  `}
`

const Subtitle = styled.h3`
  ${textStyles.subtitle}
  margin-bottom: 4px;
  text-transform: uppercase;
  color: ${colors.main["100"]};
  font-weight: 500 !important;

  ${mq.from.M`
    margin-bottom: 8px;
    font-size: 16px !important;
  `}
`

const Title = styled.h1`
  color: ${colors.purpleLight};
  ${textStyles.titleL}
  margin-bottom: 8px;

  ${mq.from.M`
    max-width: 75%;
    margin-bottom: 0;
  `}
`

const SmallTitle = styled.h3`
  color: ${colors.main["100"]};
  ${textStyles.titleM}
  margin-bottom: 8px;

  ${mq.from.M`
    max-width: 80%;
    min-width: 50%;
    margin-bottom: 0;
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;

  img {
    margin-bottom: 0;
  }

  ${mq.from.M`
    max-width: 40%;
    margin: 0;
  `}
`

const Paragraph = styled.div`
  color: ${colors.main["200"]};
  ${textStyles.body}

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  h5 {
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: ${fontWeights.medium};
    line-height: 30px;
    color: ${colors.main["100"]} !important;

    ${mq.from.M`
      font-size: 22px;
    `}
    margin-bottom: 8px;
  }

  h3 {
    ${textStyles.titleS};
    margin-bottom: 8px;
  }

  > ul,
  > ol {
    padding-left: 1em;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ${mq.from.S`
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  `}

  ${mq.from.M`
    max-width: 50%;
  `}
`

export const query = graphql`
  query resourcePage($locale: String!) {
    prismicFaqPage(lang: { eq: $locale }) {
      data {
        marketing_section {
          button_label
          button_link
          title {
            text
          }
          text {
            text
          }
          background_image {
            url
            alt
          }
        }
      }
    }
  }
`

export default ({ pageContext, data }: any) => {
  const { resource, allResources, locale, demoPage: demoPageData } = pageContext
  const { prismicFaqPage: raw } = data

  if (!resource) return null

  const formattedResource = {
    slug: resource.resource_slug,
    title: resource.resource_title?.text,
    teaser: resource.resource_teaser,
    category: resource.resource_category,
    text: resource.resource_description?.html,
    image: {
      alt: resource.resource_image?.alt,
      url: resource.resource_image?.fluid,
      imageUrl: resource.resource_image?.url,
    },
  }

  const formattedResources =
    allResources &&
    allResources.map((res: any) => ({
      slug: res.resource_slug,
      title: res.resource_title?.text,
      text: res.resource_teaser,
      category: res.resource_category,
      image: {
        alt: res.resource_image?.alt,
        url: res.resource_image?.fluid,
        imageUrl: res.resource_image?.url,
      },
    }))

  const demoPage: IDemoPage = {
    text: demoPageData.text.html,
    title: demoPageData.title.text,
    downloadText: demoPageData.download_text.html,
    downloadTitle: demoPageData.download_title.text,
    labels: {
      name: demoPageData.name,
      company: demoPageData.company,
      email: demoPageData.email,
      notes: demoPageData.notes,
      phone: demoPageData.phone,
      interest: demoPageData.interest,
      employees: demoPageData.employees,
    },
  }

  const [marketingSection] = raw.data.marketing_section

  const ourStory: IOurStory = {
    title: marketingSection?.title.text,
    text: marketingSection?.text.text,
    buttonLabel: marketingSection?.button_label,
    buttonLink: marketingSection?.button_link,
    backgroundImage: marketingSection?.background_image.url,
  }

  return (
    <ResourcePost
      {...formattedResource}
      resources={formattedResources}
      demoPage={demoPage}
      ourStory={ourStory}
      lang={locale}
    />
  )
}
